




























import Vue from 'vue';
import MainNav from '@/components/MainNav.vue';

export default Vue.extend({
  components: {
    MainNav,
  },

  data() {
    return {
      loaded: false,
    };
  },

  computed: {
    loading(): boolean {
      return !this.loaded;
    },
  },

  methods: {
    onRsvpifyFrameLoaded(): void {
      this.loaded = true;
      this.$nextTick(() => {
        (this.$refs.rsvpifyFrame as HTMLIFrameElement).focus();
      });
    },
  },
});
